import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js"
import Footer from "components/footers/FiveColumnWithBackground.js";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            {/* We have Amazing <HighlightedText>Service.</HighlightedText> */}
            Unlock lots of superpower to run your Business on Instagram
          </>
        }
      />
      <Footer />
    </AnimationRevealPage>
  );
};
